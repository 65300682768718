import React, { Component } from "react";
import styles from "./ConfirmedOffersV2.module.scss";
import {
  MAIN_QUESTION_OPTIONS,
  CONFIRMED_OFFER_QUESTIONS,
  OFFERSUMMARY_STEPS,
  MUSIC_OPTION,
  SLIDER_STEP_RANGE,
  DESCRIPTION_OF_QUESTIONS,
} from "./constants";
import {
  ACCEPT_OFFER,
  API_URL,
  ARTIST_API,
  CONFIRMED_OFFER_STEP,
  DISTRIBUTOR_FEATURE_API,
  RECOMMENDED_DEALS,
  USER_API,
  WELCOME_BACK,
} from "../constants";
import request from "../../../utils/request";
import { toast } from "react-toastify";
import { GetErrorMessage } from "../helper";
import { get, invoke } from "lodash";
import ReactRange from "../../../component/Range/ReactRange";
import { getShortNumber } from "../YourAdvance/helper";
import Odometer from "react-odometerjs";
import { AddCircleOutline, RemoveCircleOutline } from "@material-ui/icons";
import Tooltip from "../../../component/Tooltip/Tooltip";
import RecommendedDealCard from "./RecommendedDealCard";
import { FeatureDataModal } from "../YourAdvance/PartnerComparisonTool";
import { CSSTransition, TransitionGroup } from "react-transition-group";

class ConfirmedOffersV2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      step: 1,
      selectedOptions: {},
      sliderValue: undefined,
      selectedSteps: [1],
      recommendedOffers: {},
      isFeatureModalOpen: false,
      featureData: null,
      isGoBack: false,
    };
  }

  getRecommenedOffers() {
    if (
      get(this.props, "confirmedOfferSteps.preference") ===
      MAIN_QUESTION_OPTIONS[0].OPTION
    ) {
      invoke(this.props, "setLoading", true);
      const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${RECOMMENDED_DEALS}`;
      const payload = {
        method: "GET",
      };
      request(requestUrl, payload)
        .then((res) => {
          invoke(this.props, "setLoading", false);
          if (res.status) {
            this.setState({ recommendedOffers: res.data });
            return true;
          }
          toast.error(get(res, "message"));
        })
        .catch((err) => {
          invoke(this.props, "setLoading", false);
          toast.error(
            (toastProps) => (
              <GetErrorMessage err={err} toastProps={toastProps} />
            ),
            {
              className: "toast_hidden",
            },
          );
        });
    }
  }

  saveRecommenedDeal = (offerDetails, isCustomizeOffer) => {
    invoke(this.props, "setLoading", true);
    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${RECOMMENDED_DEALS}`;
    const payload = {
      isCustomizeOffer,
      offer: offerDetails,
    };
    if (isCustomizeOffer) {
      payload.offer = {
        works: offerDetails.works,
        flow_through: offerDetails.flow_through,
        royalty: offerDetails.royalty,
        tail_term: offerDetails.tail_term,
        tail_rev_share: offerDetails.tail_rev_share,
      };
    }
    const data = {
      method: "POST",
      body: payload,
    };
    request(requestUrl, data)
      .then((res) => {
        invoke(this.props, "setLoading", false);
        if (res.status) {
          if (isCustomizeOffer) {
            invoke(this.props, "setParentState", {
              isOfferScreen: true,
            });
          } else {
            invoke(this.props, "history.push", ACCEPT_OFFER);
          }
          return true;
        }
        toast.error(get(res, "message"));
      })
      .catch((err) => {
        invoke(this.props, "setLoading", false);
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
      });
  };

  toggleFeatureModal = (partner) => {
    if (typeof partner === "string" && !this.state.isFeatureModalOpen) {
      invoke(this.props, "setLoading", true);
      const data = {
        method: "GET",
      };
      const requestUrl = `${API_URL}${DISTRIBUTOR_FEATURE_API}?name=${encodeURIComponent(
        `${partner}`,
      )}`;
      request(requestUrl, data)
        .then((res) => {
          invoke(this.props, "setLoading", false);
          if (get(res, "status")) {
            this.setState({
              isFeatureModalOpen: true,
              featureData: { partner, html: get(res, "data.featuresHTML", "") },
            });
            return true;
          }
          toast.error(get(res, "message"));
          return false;
        })
        .catch((err) => {
          invoke(this.props, "setLoading", false);
          toast.error(
            (toastProps) => (
              <GetErrorMessage err={err} toastProps={toastProps} />
            ),
            {
              className: "toast_hidden",
            },
          );
        });
      return true;
    }
    this.setState({ isFeatureModalOpen: false });
  };

  componentDidUpdate(prevProps) {
    if (
      get(prevProps, "offerMinMax.min") !== get(this.props, "offerMinMax.min")
    ) {
      const defaultVal =
        get(this.props, "offerMinMax.min") + this.calculateSteps() * 3;
      if (defaultVal <= get(this.props, "offerMinMax.max")) {
        this.setState({ sliderValue: defaultVal });
      } else {
        this.setState({ sliderValue: get(this.props, "offerMinMax.min") });
      }
    }
    if (
      get(prevProps, "confirmedOfferSteps.preference") !==
      get(this.props, "confirmedOfferSteps.preference")
    ) {
      this.getRecommenedOffers();
    }
  }

  setMainOptions = (option) => {
    let step;
    if (option === MAIN_QUESTION_OPTIONS[0].OPTION) {
      step = 3;
    } else {
      step = 2;
      this.saveConfirmedOfferSteps();
      return true;
    }
    this.setState((prevState) => {
      const updatedSteps = [...(prevState.selectedSteps || [])];
      if (!updatedSteps.includes(step)) {
        updatedSteps.push(step);
      }
      return {
        step,
        selectedSteps: updatedSteps,
      };
    });
  };

  resetConfirmedOfferSteps = () => {
    this.setState({
      step: 1,
      selectedSteps: [1],
      selectedOptions: {},
    });
    const confirmedOfferSteps = {
      ...this.props.confirmedOfferSteps,
    };
    delete confirmedOfferSteps.preference;
    delete confirmedOfferSteps.works;
    delete confirmedOfferSteps.funding;
    invoke(this.props, "setParentState", {
      confirmedOfferSteps,
    });
    return true;
  };

  saveConfirmedOfferSteps = () => {
    invoke(this.props, "setLoading", true);
    const payload = {
      preference: get(this.state, "selectedOptions.MAIN_STEP"),
      works: get(this.state, "selectedOptions.MUSIC_QUESTION"),
      funding: get(this.state, "selectedOptions.FUNDING_QUESTION"),
    };
    const data = {
      method: "POST",
      body: payload,
    };
    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${CONFIRMED_OFFER_STEP}`;
    request(requestUrl, data)
      .then((res) => {
        invoke(this.props, "setLoading", false);
        if (res.status) {
          if (payload.preference === MAIN_QUESTION_OPTIONS[1].OPTION) {
            invoke(this.props, "setParentState", { isOfferScreen: true });
            return false;
          }
          const confirmedOfferSteps = {
            ...this.props.confirmedOfferSteps,
            ...payload,
          };
          invoke(this.props, "setParentState", {
            confirmedOfferSteps,
          });
          return true;
        }
        toast.error(get(res, "message"));
      })
      .catch((err) => {
        invoke(this.props, "setLoading", false);
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
      });
  };

  setMusicOptions = (option) => {
    const step = 4;
    this.setState((prevStep) => {
      const updatedSteps = [...(prevStep.selectedSteps || [])];
      if (!updatedSteps.includes(step)) {
        updatedSteps.push(step);
      }
      return {
        step,
        selectedSteps: updatedSteps,
      };
    });
  };

  updateOfferSummaryModule = (option, stepValue) => {
    this.setState(
      (prevState) => ({
        selectedOptions: {
          ...prevState.selectedOptions,
          [stepValue]: option,
        },
      }),
      () => {
        if (stepValue === OFFERSUMMARY_STEPS[0]) {
          this.setMainOptions(option);
        } else if (stepValue === OFFERSUMMARY_STEPS[1]) {
          this.setMusicOptions(option);
        } else if (stepValue === OFFERSUMMARY_STEPS[2]) {
          this.saveConfirmedOfferSteps();
        }
      },
    );
  };

  renderOptionContainer = (questionOptions, stepValue) => {
    const lastSelectedOption = this.state.selectedOptions[stepValue] || null;
    return (
      <>
        {questionOptions.map((option) => (
          <button
            className={`${styles.optionContainer} ${
              lastSelectedOption === option.OPTION
                ? styles.highlightedOption
                : ""
            }`}
            key={option.OPTION}
            onClick={() =>
              this.updateOfferSummaryModule(option.OPTION, stepValue)
            }
          >
            <h2>{option.OPTION}</h2>
            {option.DESCRIPTION && <p>{option.DESCRIPTION}</p>}
          </button>
        ))}
      </>
    );
  };

  renderToBackPage = (stepValue) => {
    this.setState({ isGoBack: true });
    this.setState((prevState) => {
      const updatedStep = [...prevState.selectedSteps];
      if (updatedStep.length > 1) {
        updatedStep.pop();
      }
      const previousStep = updatedStep[updatedStep.length - 1] || null;
      const updatedSelectedOptions = { ...prevState.selectedOptions };

      if (stepValue in updatedSelectedOptions) {
        delete updatedSelectedOptions[stepValue];
      }

      this.setState({
        step: previousStep,
        selectedSteps: updatedStep,
        selectedOptions: updatedSelectedOptions,
      });
    });
  };

  getQuestionAnswerContainer = (
    selectedQuestion,
    questionOptions,
    stepValue,
    description,
  ) => {
    return (
      <>
        <div
          className={styles.mainQuestionContainer}
          onClick={() => {
            this.setState({ isGoBack: false });
          }}
        >
          <TransitionGroup
            className={`${styles.transitionContainer} ${
              get(this.state, "isGoBack") ? styles.back : ""
            }`}
          >
            <CSSTransition
              key={`questions-${get(this.state, "step")}`}
              timeout={2000}
              classNames={"question-swipe"}
            >
              <div className={styles.swipe}>
                <div className={styles.titleQuestionContainer}>
                  <h2>{selectedQuestion}</h2>
                  <p>{description}</p>
                </div>
                <div className={styles.answerContainer}>
                  {this.renderOptionContainer(questionOptions, stepValue)}
                </div>
              </div>
            </CSSTransition>
          </TransitionGroup>
        </div>
        {selectedQuestion !== CONFIRMED_OFFER_QUESTIONS.MAIN_QUESTION ? (
          <button
            className={styles.goBackBtn}
            onClick={() => this.renderToBackPage(stepValue)}
          >
            Go Back
          </button>
        ) : (
          <button
            className={styles.goBackBtn}
            onClick={() => invoke(this.props, "history.push", WELCOME_BACK)}
          >
            Go Back
          </button>
        )}
      </>
    );
  };

  calculateSteps = () => {
    if (get(this.props, "offerMinMax.max", 0) <= SLIDER_STEP_RANGE[0])
      return 1000;
    if (get(this.props, "offerMinMax.max", 0) <= SLIDER_STEP_RANGE[1])
      return 5000;
    if (get(this.props, "offerMinMax.max", 0) <= SLIDER_STEP_RANGE[2])
      return 10000;
    return 50000;
  };

  handleSliderButton = (isPlus) => {
    this.setState((prev) => {
      const value = isPlus
        ? get(prev, "sliderValue", 0) + this.calculateSteps()
        : get(prev, "sliderValue", 0) - this.calculateSteps();
      if (value < get(this.props, "offerMinMax.min", 0))
        return { sliderValue: get(this.props, "offerMinMax.min", 0) };
      if (value > get(this.props, "offerMinMax.max", 0))
        return { sliderValue: get(this.props, "offerMinMax.max", 0) };
      return { sliderValue: value };
    });
  };

  renderFundingSlider = (selectedQuestion, stepValue, description) => (
    <>
      <div className={styles.mainQuestionContainer}>
        <TransitionGroup
          className={`${styles.transitionContainer} ${
            get(this.state, "isGoBack") ? styles.back : ""
          }`}
        >
          <CSSTransition
            key={`questions-${get(this.state, "step")}`}
            timeout={2000}
            classNames={"question-swipe"}
          >
            <div className={styles.swipe}>
              <div className={styles.titleQuestionContainer}>
                <h2>{selectedQuestion}</h2>
                <p>{description}</p>
              </div>
              <div className={styles.answerContainer}>
                <div className={styles.fundingSlider}>
                  <div className={styles.sliderValContainer}>
                    <button
                      className={styles.sliderBtn}
                      onClick={() => {
                        this.handleSliderButton();
                      }}
                      data-testid="sliderBtn"
                    >
                      <RemoveCircleOutline />
                    </button>
                    {!!get(this.state, "sliderValue") && (
                      <span>
                        $
                        <Odometer
                          value={getShortNumber(get(this.state, `sliderValue`))}
                          format="(,ddd).dd"
                          duration={400}
                        />
                        {`${getShortNumber(
                          get(this.state, `sliderValue`),
                        )}`.replace(/[\d,\.]/g, "")}
                      </span>
                    )}
                    <button
                      className={styles.sliderBtn}
                      onClick={() => {
                        this.handleSliderButton(true);
                      }}
                      data-testid="sliderBtn"
                    >
                      <AddCircleOutline />
                    </button>
                  </div>
                  <div className={styles.sliderContainer}>
                    <span className={styles.minMax}>
                      ${getShortNumber(get(this.props, "offerMinMax.min"))}
                    </span>
                    <ReactRange
                      values={[
                        get(
                          this.state,
                          "sliderValue",
                          get(this.props, "offerMinMax.min"),
                        ),
                      ]}
                      onFinalChange={(val) => {
                        this.setState({ sliderValue: val[0] });
                      }}
                      min={get(this.props, "offerMinMax.min")}
                      max={get(this.props, "offerMinMax.max")}
                      step={this.calculateSteps()}
                      hideValue
                    />
                    <span className={styles.minMax}>
                      ${getShortNumber(get(this.props, "offerMinMax.max"))}
                    </span>
                  </div>
                  <button
                    className={styles.sliderSubmit}
                    onClick={() => {
                      this.updateOfferSummaryModule(
                        get(this.state, "sliderValue"),
                        stepValue,
                      );
                    }}
                    data-testid="sliderContinue"
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </CSSTransition>
        </TransitionGroup>
      </div>
      <button
        className={styles.goBackBtn}
        onClick={() => this.renderToBackPage(stepValue)}
      >
        Go Back
      </button>
    </>
  );

  renderComponentByStep = () => {
    const { step } = this.state;
    switch (step) {
      case 1:
        return this.getQuestionAnswerContainer(
          CONFIRMED_OFFER_QUESTIONS.MAIN_QUESTION,
          MAIN_QUESTION_OPTIONS,
          OFFERSUMMARY_STEPS[0],
        );
      case 3:
        return this.getQuestionAnswerContainer(
          CONFIRMED_OFFER_QUESTIONS.MUSIC_QUESTION,
          MUSIC_OPTION,
          OFFERSUMMARY_STEPS[1],
          DESCRIPTION_OF_QUESTIONS.MUSIC_QUESTION,
        );
      case 4:
        return this.renderFundingSlider(
          CONFIRMED_OFFER_QUESTIONS.FUNDING_SLIDER_QUESTION,
          OFFERSUMMARY_STEPS[2],
          DESCRIPTION_OF_QUESTIONS.FUNDING_SLIDER_QUESTION,
        );
    }
  };

  render() {
    return (
      <div className={styles.confirmedOfferContainer}>
        {get(this.props, "confirmedOfferSteps.preference") !==
        MAIN_QUESTION_OPTIONS[0].OPTION ? (
          <>
            <div className={styles.mobOfferRange}>
              <p>
                <span>Confirmed Offers Range</span>{" "}
                <Tooltip
                  place="right"
                  light
                  id="offerRange"
                  content={
                    "This is the range of funding you can choose from when selecting an advance. Not all exact dollar figures within the range will be available."
                  }
                  delay={200}
                />
              </p>
              <div className={styles.rangeNumbers}>
                ${getShortNumber(get(this.props, "offerMinMax.min"))} - $
                {getShortNumber(get(this.props, "offerMinMax.max"))}
              </div>
            </div>
            {this.renderComponentByStep()}
          </>
        ) : (
          <div className={styles.recommendedConiner}>
            <div className={styles.recommendedDealContainer}>
              <RecommendedDealCard
                offers={this.state.recommendedOffers?.one}
                toggleFeatureModal={this.toggleFeatureModal}
                saveRecommenedDeal={this.saveRecommenedDeal}
              />
              <RecommendedDealCard
                offers={this.state.recommendedOffers?.two}
                toggleFeatureModal={this.toggleFeatureModal}
                saveRecommenedDeal={this.saveRecommenedDeal}
              />
              <RecommendedDealCard
                offers={this.state.recommendedOffers?.three}
                toggleFeatureModal={this.toggleFeatureModal}
                saveRecommenedDeal={this.saveRecommenedDeal}
              />
              <div className={styles.mobileGoBackBtn}>
                <button
                  className={styles.goBackBtn}
                  data-testid="mobile-footer-goback-btn"
                  onClick={this.resetConfirmedOfferSteps}
                >
                  Go Back
                </button>
              </div>
            </div>
            <div className={styles.footerContainer}>
              <div className={styles.backBtnContainer}>
                <button
                  className={styles.goBackBtn}
                  data-testid="footer-goback-btn"
                  onClick={this.resetConfirmedOfferSteps}
                >
                  Go Back
                </button>
              </div>
              <div className={styles.offersBtnContainer}>
                <button
                  className={styles.secondary}
                  data-testid="view-other-btn"
                  onClick={() =>
                    invoke(this.props, "setParentState", {
                      isOfferScreen: true,
                    })
                  }
                >
                  View Other Option
                </button>
                <button
                  className={styles.primary}
                  data-testid="compare-offer-btn"
                  onClick={() => console.log("Compare Offers")}
                >
                  Compare Offers
                </button>
              </div>
            </div>
            <FeatureDataModal
              isOpen={this.state.isFeatureModalOpen}
              toggleFeatureModal={this.toggleFeatureModal}
              featureData={this.state.featureData}
            />
          </div>
        )}
      </div>
    );
  }
}

export default ConfirmedOffersV2;
