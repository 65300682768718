export const DEFAULT_FLOW_THROUGH = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90];

export const DEFAULT_TERMS = [1, 2, 3, 5, 8, 20];

export const DEFAULT_ROYALTY = [50, 60, 70, 80, 90];

export const OFFER_KEYS = ['Included Works',
    'Percent of Income Paid Through Before Recoupment',
    'Recoupment Rate',
    'Post-Recoupment Term Length',
    'Income Paid Through After Recoupment',
    'Distributor'
]

export const YT_URL = {
    WORKS: {
        BB: "https://www.youtube.com/watch?v=MnFNg7UWlRk",
        CC: "https://www.youtube.com/watch?v=ZitSiRW6SMw"
    },
    INCOME_PAID_BEFORE_RECOUP: {
        BB: "https://www.youtube.com/watch?v=MnFNg7UWlRk",
        CC: "https://www.youtube.com/watch?v=ZitSiRW6SMw"
    },
    RECOUPMENT_RATE: {
        BB: "https://www.youtube.com/watch?v=MnFNg7UWlRk",
        CC: "https://www.youtube.com/watch?v=ZitSiRW6SMw"
    },
    TERM_LENGTH: {
        BB: "https://www.youtube.com/watch?v=MnFNg7UWlRk",
        CC: "https://www.youtube.com/watch?v=ZitSiRW6SMw"
    },
    INCOME_PAID_AFTER_RECOUP: {
        BB: "https://www.youtube.com/watch?v=MnFNg7UWlRk",
        CC: "https://www.youtube.com/watch?v=ZitSiRW6SMw"
    },
    DISTRIBUTOR: {
        BB: "https://www.youtube.com/watch?v=MnFNg7UWlRk",
        CC: "https://www.youtube.com/watch?v=ZitSiRW6SMw"
    }
}

export const YT_VIDEO_DESC = [
    "works caption",
    "before income Captions",
    "recoupment rate Captions",
    "term length captions",
    "after income captions",
    "distributor"
]
