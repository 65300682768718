export const FINAL_CHECKOUT_MESSAGE = {
  PARTNER_ADVANCES: "partner_advances",
  MOVE_DISTRIBUTORS: "move_distributors",
  TAKE_CONTROL_OF_ACCOUNT: "take_control_of_account",
  SECURE_INCOME: "secure_income",
  MUST_MOVE_PARTNER_ADVANCE: "must_move_partner_advance",
};

export const YT_DEFAULT_LINK = "https://www.youtube.com/watch?v=t3YUN78IX7A";

export const APPROVAL_MESSAGE_WITH_YOUTUBE_LINK = [
  {
    MESSAGE: "take_control_of_account",
    YT_LINK: "https://www.youtube.com/watch?v=zJ2TNVI50PE",
  },
];
