import React, { Component } from "react";
import styles from "./ConfirmedOffers.module.scss";
import { setTitle } from "../../../component/ThemeManager/helper";
import HorizontalMenu from "../../../component/HorizontalMenu";
import LoginHeader from "../../../component/LoginHeader";
import Loader from "../../../component/Loader";
import ShortCallLoader from "../../../component/Loader/ShortCallLoader";
import { FinePrintModal, getShortNumber } from "../YourAdvance/helper";
import Odometer from "react-odometerjs";
import { get, invoke, isObject, values } from "lodash";
import Tooltip from "../../../component/Tooltip/Tooltip";
import Select from "react-select";
import { distributorSelectStyle } from "../../../component/UploadFiles/selectStyle";
import {
  CURRENT_DISTRIBUTOR_LABEL,
  DEFAULT_PARTNER,
} from "../YourAdvance/constants";
import Image from "../../../component/Image";
import {
  ACCEPT_OFFER,
  API_URL,
  ARTIST_API,
  CONFIRMED_OFFER_STEP,
  CONFIRMED_OFFERS_API,
  ERROR_SERVER_DOWN,
  OFFER_SUMMARY,
  TOP_TRACK,
  USER_API,
} from "../constants";
import request from "../../../utils/request";
import { toast } from "react-toastify";
import { GetErrorMessage, GetIframeVideo } from "../helper";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { ReactComponent as IconDownArrow } from "../../../assets/logos/dropDownArrow.svg";
import ConfirmedOffersV2 from "../ConfirmedOffersV2/ConfirmedOffersV2";
import moment from "moment";
import { MAIN_QUESTION_OPTIONS } from "../ConfirmedOffersV2/constants";
import { ReactComponent as ToolTip } from "../../../assets/logos/help-icon.svg";
import { OFFER_KEYS, YT_URL, YT_VIDEO_DESC } from "./constants";
import ReactModal from "react-modal";
import { ThemeContext } from "../../../component/ThemeManager/ThemeManager";
import PropTypes from "prop-types";

class ConfirmedOffers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      loadingRange: false,
      shortLoading: false,
      advanceData: {},
      currentData: {
        tail_term: 0,
        tail_rev_share: "0%",
      },
      defaultOffer: {},
      flatOffer: [],
      selectedDeal: "",
      offerStage: {},
      selectedAdvance: 0,
      navigationStatus: {},
      selectedArtist: {},
      availablePartners: [],
      selectedPartner: null,
      partnersOptions: {},
      isFinePrint: false,
      isSaveOffer: false,
      advanceOpened: false,
      minMaxValues: {
        advance: {
          min: 0,
          max: 0,
        },
      },
      selections: {
        partner: null,
        works: null,
        flow_through: null,
        royalty: null,
        tail_term: null,
        tail_rev_share: null,
      },
      filteredOptions: {},
      groupedValues: {},
      isOfferScreen: false,
      showMoreData: false,
      isModalOpen: false,
      videoTitle: null,
      videoDescription: null,
      ytUrl: null,
    };
  }

  componentDidMount() {
    this.getAdvanceData();
  }

  componentDidUpdate(prevProps, prevState) {
    setTitle("CONFIRMED OFFERS", this.context);
    if (
      get(this.state, "isOfferScreen") &&
      get(prevState, "isOfferScreen") !== get(this.state, "isOfferScreen")
    ) {
      this.getOffers(true);
    }
  }

  getAdvanceData = () => {
    this.setState({ loading: true });
    const data = {
      method: "GET",
    };
    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${TOP_TRACK}?isTopTrackDataNeeded=false`;
    request(requestUrl, data)
      .then((res) => {
        this.setState({
          loading: false,
        });
        if (get(res, "status")) {
          this.checkSteps(
            res.data,
            get(this.props, "location.state.fromDashboard"),
          );

          this.setState({
            offerStage: res.data.offerStage,
            availablePartners: get(res, "data.availablePartners", []),
          });
          return true;
        }
        toast.error(get(res, "message"));
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
        this.props.history.push(ERROR_SERVER_DOWN);
      });
  };

  checkSteps = (data, fromDashboard) => {
    if (fromDashboard) {
      if (
        get(data, "offerStage.defaultOffer.isAccepted") ||
        (get(data, "offerStage.verificationStatus.adjustTerms") &&
          get(data, "offerStage.verificationStatus.offerSummary"))
      ) {
        this.props.history.push({
          pathname: ACCEPT_OFFER,
          state: { reviewData: data },
        });
        return false;
      }
      if (!get(data, "renderNewConfirmedOffers")) {
        invoke(this.props, "history.push", OFFER_SUMMARY);
        return false;
      }
    }
    this.getMinMaxRange();
    return true;
  };

  getMinMaxRange = () => {
    this.setState({ loadingRange: true });
    const data = {
      method: "GET",
    };

    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${CONFIRMED_OFFER_STEP}`;
    request(requestUrl, data)
      .then((res) => {
        this.setState({
          loadingRange: false,
        });
        if (get(res, "status")) {
          this.setState({
            offerMinMax: {
              min: get(res, "data.confirmedOfferSteps.min"),
              max: get(res, "data.confirmedOfferSteps.max"),
            },
            confirmedOfferSteps: get(res, "data.confirmedOfferSteps"),
          });
          return true;
        }
        toast.error(get(res, "message"));
      })
      .catch((err) => {
        this.setState({ loadingRange: false });
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
        this.props.history.push(ERROR_SERVER_DOWN);
      });
  };

  getPartnerOptionObject = (data) => {
    let currObject = get(data, "offers", {});
    let prevObj = null;
    while (isObject(values(currObject)[0])) {
      prevObj = currObject;
      currObject = values(currObject)[0];
    }
    return prevObj;
  };

  getOffers = (loadDefault) => {
    this.setState({ loading: false, shortLoading: true });
    const data = {
      method: "GET",
    };
    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${CONFIRMED_OFFERS_API}`;
    request(requestUrl, data)
      .then((res) => {
        this.setState({ loading: false, shortLoading: false });
        if (res.status) {
          this.setState(
            {
              advanceData: get(res, "data.offers", {}),
              defaultOffer: get(res, "data.selectedOffer", {}),
              currentData: {
                tail_term: get(res, "data.selectedOffer.tail_term", 0),
                tail_rev_share: get(
                  res,
                  "data.selectedOffer.tail_rev_share",
                  "0%",
                ),
                partner: get(res, "data.selectedOffer.partner"),
                works: get(
                  res,
                  "data.selectedOffer.works",
                  Object.keys(get(res, "data.offers", {}))[0],
                ),
                flow_through: get(res, "data.selectedOffer.flow_through"),
                royalty: get(res, "data.selectedOffer.royalty"),
              },
              selections: {
                works: get(
                  res,
                  "data.selectedOffer.works",
                  Object.keys(get(res, "data.offers", {}))[0] || null,
                ),
                flow_through: get(res, "data.selectedOffer.flow_through", null),
                royalty: get(res, "data.selectedOffer.royalty", null),
                tail_term: get(res, "data.selectedOffer.tail_term", 0),
                tail_rev_share: get(
                  res,
                  "data.selectedOffer.tail_rev_share",
                  null,
                ),
                partner: this.isPartnerFlow()
                  ? get(res, "data.selectedOffer.partner", null)
                  : null,
              },
              selectedPartner: get(
                res,
                "data.selectedOffer.partner",
                get(this.state, "availablePartners[0]", null),
              ),
              partnersOptions: this.getPartnerOptionObject(
                get(res, "data", {}),
              ),
            },
            () => {
              this.updateMinMaxValues(
                JSON.parse(JSON.stringify(this.state.advanceData)),
                loadDefault,
              );
            },
          );
          return true;
        }
        toast.error(get(res, "message"));
      })
      .catch((err) => {
        this.setState({ loading: false, shortLoading: false });
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
      });
  };

  handleSaveOffer = () => {
    this.setState({ loading: false, shortLoading: true, isSaveOffer: true });
    const payload = {
      offer: {
        ...this.state.currentData,
        total_advance: get(this.state, "minMaxValues.advance.min"),
      },
      adjustTerms: true,
    };
    const data = {
      method: "POST",
      body: payload,
    };
    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${CONFIRMED_OFFERS_API}`;
    request(requestUrl, data)
      .then((res) => {
        this.setState({
          loading: false,
          shortLoading: false,
          isSaveOffer: false,
        });
        if (res.status) {
          this.props.history.push(ACCEPT_OFFER);
          toast.success(get(res, "message"));
          return true;
        }
        toast.error(get(res, "message"));
      })
      .catch((err) => {
        this.setState({
          shortLoading: false,
          loading: false,
          isSaveOffer: false,
        });
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
      });
  };

  toggleFinePrintText = () => {
    this.setState({ isFinePrint: !this.state.isFinePrint });
  };

  flattenObject = (data) => {
    const stack = [data];
    const values = [];
    const minMaxValues = {
      advance: { min: Infinity, max: 0 },
      royalty: { min: Infinity, max: 0 },
      option_advance: { min: Infinity, max: 0 },
      new_release_advance: { min: Infinity, max: 0 },
      catalog_advance: { min: Infinity, max: 0 },
      recoup_year_low: Infinity,
      recoup_year_high: 0,
    };

    while (stack.length > 0) {
      const current = stack.pop();
      if (get(current, "works")) {
        values.push(current);

        const advance = get(current, "total_advance", 0);
        const royalty = parseFloat(get(current, "royalty", 0));
        const option_advance = parseFloat(get(current, "option_advance", 0));
        const new_release_advance = parseFloat(
          get(current, "new_release_advance", 0),
        );
        const catalog_advance = parseFloat(get(current, "catalog_advance", 0));
        const recoup_year_low = parseFloat(get(current, "recoup_year_low", 0));
        const recoup_year_high = parseFloat(
          get(current, "recoup_year_high", 0),
        );

        minMaxValues.advance.min = Math.min(minMaxValues.advance.min, advance);
        minMaxValues.advance.max = Math.max(minMaxValues.advance.max, advance);
        minMaxValues.royalty.min = Math.min(minMaxValues.royalty.min, royalty);
        minMaxValues.royalty.max = Math.max(minMaxValues.royalty.max, royalty);

        minMaxValues.option_advance.min = Math.min(
          minMaxValues.option_advance.min,
          option_advance,
        );
        minMaxValues.option_advance.max = Math.max(
          minMaxValues.option_advance.max,
          option_advance,
        );

        minMaxValues.new_release_advance.min = Math.min(
          minMaxValues.new_release_advance.min,
          new_release_advance,
        );
        minMaxValues.new_release_advance.max = Math.max(
          minMaxValues.new_release_advance.max,
          new_release_advance,
        );

        minMaxValues.catalog_advance.min = Math.min(
          minMaxValues.catalog_advance.min,
          catalog_advance,
        );
        minMaxValues.catalog_advance.max = Math.max(
          minMaxValues.catalog_advance.max,
          catalog_advance,
        );
        minMaxValues.recoup_year_low = Math.min(
          minMaxValues.recoup_year_low,
          recoup_year_low,
        );
        minMaxValues.recoup_year_high = Math.max(
          minMaxValues.recoup_year_high,
          recoup_year_high,
        );
      }
      for (const key in current) {
        if (current[key] && typeof current[key] === "object") {
          stack.push(current[key]);
        }
      }
    }

    return { values, minMaxValues };
  };

  generateGroupedValues = (data) => {
    const groupedValues = {};
    if (!get(data, "length")) return groupedValues;
    Object.keys(this.state.selections).forEach((field) => {
      groupedValues[field] = [];
    });
    invoke(data, "forEach", (item) => {
      Object.keys(groupedValues).forEach((field) => {
        if (!groupedValues[field].includes(item[field])) {
          groupedValues[field].push(item[field]);
        }
      });
    });
    Object.keys(groupedValues).forEach((field) => {
      groupedValues[field].sort((a, b) =>
        typeof a === "number" ? a - b : invoke(a, "localeCompare", b),
      );
    });

    return groupedValues;
  };

  filterOptions = (data, update = true) => {
    const { selections } = this.state;

    const minMaxValues = {
      advance: { min: Infinity, max: 0 },
      royalty: { min: Infinity, max: 0 },
      option_advance: { min: Infinity, max: 0 },
      new_release_advance: { min: Infinity, max: 0 },
      catalog_advance: { min: Infinity, max: 0 },
      recoup_year_low: Infinity,
      recoup_year_high: 0,
    };
    const grouped = JSON.parse(JSON.stringify(this.state.groupedValues));
    const filtered = {};
    const fields = Object.keys(grouped);

    fields.forEach((field) => {
      filtered[field] = [];
    });

    data.forEach((item) => {
      let matchesAllSelections = true;
      Object.keys(selections).forEach((selField) => {
        if (
          [null, undefined].indexOf(selections[selField]) === -1 &&
          selections[selField] !== item[selField]
        ) {
          matchesAllSelections = false;
        }
      });
      if (matchesAllSelections) {
        fields.forEach((field) => {
          if (!filtered[field].includes(item[field])) {
            filtered[field].push(item[field]);
          }
        });

        const advance = get(item, "total_advance", 0);
        const royalty = parseFloat(get(item, "royalty", 0));
        const option_advance = parseFloat(get(item, "option_advance", 0));
        const new_release_advance = parseFloat(
          get(item, "new_release_advance", 0),
        );
        const catalog_advance = parseFloat(get(item, "catalog_advance", 0));
        const recoup_year_low = parseFloat(get(item, "recoup_year_low", 0));
        const recoup_year_high = parseFloat(get(item, "recoup_year_high", 0));

        minMaxValues.advance.min = Math.min(minMaxValues.advance.min, advance);
        minMaxValues.advance.max = Math.max(minMaxValues.advance.max, advance);
        minMaxValues.royalty.min = Math.min(minMaxValues.royalty.min, royalty);
        minMaxValues.royalty.max = Math.max(minMaxValues.royalty.max, royalty);
        minMaxValues.option_advance.min = Math.min(
          minMaxValues.option_advance.min,
          option_advance,
        );
        minMaxValues.option_advance.max = Math.max(
          minMaxValues.option_advance.max,
          option_advance,
        );
        minMaxValues.new_release_advance.min = Math.min(
          minMaxValues.new_release_advance.min,
          new_release_advance,
        );
        minMaxValues.new_release_advance.max = Math.max(
          minMaxValues.new_release_advance.max,
          new_release_advance,
        );
        minMaxValues.catalog_advance.min = Math.min(
          minMaxValues.catalog_advance.min,
          catalog_advance,
        );
        minMaxValues.catalog_advance.max = Math.max(
          minMaxValues.catalog_advance.max,
          catalog_advance,
        );
        minMaxValues.recoup_year_low = Math.min(
          minMaxValues.recoup_year_low,
          recoup_year_low,
        );
        minMaxValues.recoup_year_high = Math.max(
          minMaxValues.recoup_year_high,
          recoup_year_high,
        );
      }
    });
    Object.keys(filtered).forEach((field) => {
      filtered[field] = [...new Set(filtered[field])].sort((a, b) =>
        typeof a === "number" ? a - b : invoke(a, "localeCompare", b),
      );
    });

    this.setState({
      filteredOptions: update ? filtered : this.state.filteredOptions,
      minMaxValues,
    });
  };

  updateMinMaxValues = (data, firstTime) => {
    const relevantData = this.flattenObject(data);
    if (firstTime) {
      const groupedValues = this.generateGroupedValues(relevantData.values);
      this.filterOptions(relevantData.values);
      this.setState({ groupedValues });
    }
    this.setState({
      flatOffer: [...relevantData.values],
    });
  };

  isPartnerFlow = () => !!get(this.state, "availablePartners.length");

  loaderText = () => {
    if (this.state.isSaveOffer) {
      return (
        <p>
          We are generating <br /> your<span> deal summary</span>
        </p>
      );
    }
    return (
      <p>
        We are retrieving <br /> your<span> offers</span>
      </p>
    );
  };

  getPartnerOptions = () =>
    get(this.state, "availablePartners", []).map((o) => ({
      label: o === DEFAULT_PARTNER ? CURRENT_DISTRIBUTOR_LABEL : o,
      value: o,
    }));

  getSelectedPartner = () =>
    get(this.state, "currentData.partner", null) && {
      label:
        get(this.state, "currentData.partner") === DEFAULT_PARTNER
          ? CURRENT_DISTRIBUTOR_LABEL
          : get(this.state, "currentData.partner"),
      value: get(this.state, "currentData.partner"),
    };

  getWorksOptions = () =>
    Object.keys(get(this.state, "advanceData", {})).map((o) => ({
      label: o,
      value: o,
    }));

  formatOptionLabel = (op) => (
    <div className={styles.option}>
      {op.value !== DEFAULT_PARTNER && (
        <Image
          src={get(this.state, `partnersOptions.${op.value}.logo`)}
          alt={op.value}
        />
      )}
      <span>{op.label}</span>
    </div>
  );

  handleChangeOffer = (field, value, update = true) => {
    const keyOrder = [
      "works",
      "flow_through",
      "royalty",
      "tail_term",
      "tail_rev_share",
    ];

    const currentData = {
      ...get(this.state, "currentData", {}),
      [field]: value,
    };
    const keyIndex = keyOrder.indexOf(field);
    const selections = { ...get(this.state, "selections", {}), [field]: value };
    if (keyIndex !== -1) {
      for (let i = keyIndex + 1; i < keyOrder.length; i++) {
        const nextKey = keyOrder[i];
        delete currentData[nextKey];
        selections[nextKey] = null;
      }
      if (!get(selections, "tail_term") && get(selections, "royalty")) {
        selections.tail_term = 0;
      }
    }

    this.setState(
      (prev) => ({
        currentData,
        selections,
      }),
      () => {
        this.filterOptions(this.state.flatOffer, update);
      },
    );
  };

  renderMinMaxOffer = (key) => {
    return get(this.state, `minMaxValues.${key}.min`) ===
      get(this.state, `minMaxValues.${key}.max`) ? (
      <h3>
        $
        {get(this.state, `minMaxValues.${key}.min`) !== Infinity && (
          <Odometer
            value={get(this.state, `minMaxValues.${key}.min`)}
            format="(,ddd).dd"
            duration={400}
          />
        )}
      </h3>
    ) : (
      <h3>
        $
        {get(this.state, `minMaxValues.${key}.min`) !== Infinity && (
          <Odometer
            value={getShortNumber(get(this.state, `minMaxValues.${key}.min`))}
            format="(,ddd).dd"
            duration={400}
          />
        )}
        {`${getShortNumber(
          get(this.state, `minMaxValues.${key}.min`),
        )}`.replace(/[\d,\.]/g, "")}{" "}
        -{" "}
        {get(this.state, `minMaxValues.${key}.max`) !== -Infinity && (
          <Odometer
            value={getShortNumber(get(this.state, `minMaxValues.${key}.max`))}
            format="(,ddd).dd"
            duration={400}
          />
        )}
        {`${getShortNumber(
          get(this.state, `minMaxValues.${key}.max`),
        )}`.replace(/[\d,\.]/g, "")}
      </h3>
    );
  };

  getYTURL = (title) => {
    let ytUrl;
    if (
      get(this.context, "slugName") === "app" ||
      get(this.context, "slugName") === "local"
    ) {
      ytUrl = YT_URL[title].BB;
    } else {
      ytUrl = YT_URL[title].CC;
    }
    return ytUrl;
  };

  renderVideoContainer = (title) => {
    const index = OFFER_KEYS.indexOf(title);
    const videoTitle = OFFER_KEYS[index];
    const ytUrl = this.getYTURL(Object.keys(YT_URL)[index]);
    const videoDescription = YT_VIDEO_DESC[index];
    this.setState({ isModalOpen: true, videoTitle, videoDescription, ytUrl });
  };

  renderModal = () => {
    return (
      <ReactModal
        isOpen={this.state.isModalOpen}
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
        onRequestClose={this.handleCloseModal}
        className={styles.detailsPopupContainer}
        overlayClassName={styles.modalOverlay}
      >
        <div className={styles.videoContainer}>
          <h2>{this.state.videoTitle}</h2>
          <span className={styles.closeBtn} onClick={this.handleCloseModal}>
            {" "}
            &times;
          </span>
          <div className={styles.video} style={{ cursor: "pointer" }}>
            <GetIframeVideo url={this.state.ytUrl} />
          </div>
          <p>{this.state.videoDescription}</p>
        </div>
      </ReactModal>
    );
  };

  handleCloseModal = () => {
    this.setState({ isModalOpen: false, videoTitle: "" });
  };

  renderOfferInfo = () => (
    <>
      {get(this.state, "currentData.works") && (
        <div className={styles.royalty}>
          <p>Works</p>
          <p>{get(this.state, "currentData.works")}</p>
        </div>
      )}
      {!!get(this.state, "currentData.flow_through") && (
        <div className={styles.royalty}>
          <p>
            <span>Percent of Income Retained</span>
            <Tooltip
              place="top"
              light
              id="flow through"
              delay={100}
              content={
                "We can pay through a percentage of your monthly income during your deal."
              }
            />
          </p>
          <p>{get(this.state, "currentData.flow_through")}%</p>
        </div>
      )}
      <div className={styles.royalty}>
        <p>
          <span>Recoupment Rate</span>
          <Tooltip
            place="top"
            light
            id="Royalty Rate"
            delay={100}
            content={
              "This is the percentage of your distribution revenue that we collect that goes toward recouping your advance."
            }
          />
        </p>
        {get(this.state, "currentData.royalty") ? (
          <p>{get(this.state, "minMaxValues.royalty.min")}%</p>
        ) : (
          <p>
            {get(this.state, "minMaxValues.royalty.min")}% -{" "}
            {get(this.state, `minMaxValues.royalty.max`)}%
          </p>
        )}
      </div>
      {!!get(this.state, `currentData.tail_term`) && (
        <div className={styles.royalty}>
          <p>
            <span>Post-Recoup Term Length</span>
            <Tooltip
              place="top"
              light
              id="Post-Recoup Term Length"
              delay={100}
              content={""}
            />
          </p>
          <p>{get(this.state, `currentData.tail_term`)} Years</p>
        </div>
      )}
      {!!parseFloat(get(this.state, "currentData.tail_rev_share")) && (
        <div className={styles.royalty}>
          <p>
            <span>Percent of Income Paid Through </span>
            <Tooltip
              place="top"
              light
              id="Percent of Income Paid  Through"
              delay={100}
              content={""}
            />
          </p>
          <p>{get(this.state, "currentData.tail_rev_share")}</p>
        </div>
      )}
    </>
  );

  toggleShowMoreData = () => {
    this.setState({ showMoreData: !get(this.state, "showMoreData") });
  };

  renderCustomizeOffers = () => {
    const flowThroughOptions = Object.keys(
      get(
        this.state,
        `advanceData.${get(this.state, "currentData.works", "")}`,
        {},
      ),
    ).map(Number);

    const royaltyOptions = Object.keys(
      get(
        this.state,
        `advanceData.${get(this.state, "currentData.works", "")}.${get(
          this.state,
          "currentData.flow_through",
          "",
        )}`,
        {},
      ),
    );

    const tailTermOptions = Object.keys(
      get(
        this.state,
        `advanceData.${get(this.state, "currentData.works", "")}.${get(
          this.state,
          "currentData.flow_through",
          "",
        )}.${get(this.state, "currentData.royalty", "")}`,
        {},
      ),
    ).map(Number);

    const tailShareOptions = Object.keys(
      get(
        this.state,
        `advanceData.${get(this.state, "currentData.works", "")}.${get(
          this.state,
          "currentData.flow_through",
          "",
        )}.${get(this.state, "currentData.royalty", "")}.${get(
          this.state,
          "currentData.tail_term",
          "",
        )}`,
        {},
      ),
    );

    return (
      <div className={styles.customizeFundingContainer}>
        <div className={styles.rightContainer}>
          <div className={styles.floatHeader}>
            <div className={styles.titleContainer}>
              <h2>
                <span>Confirmed offer summary</span>
              </h2>
              <p>
                Valid through{" "}
                {moment(
                  get(this.state, "offerStage.defaultOffer.expire"),
                ).format("MMMM DD,YYYY")}
              </p>
            </div>
            <div className={styles.offerOverview}>
              <div className={styles.advanceContainer}>
                <p>Total Advance</p>
                {this.renderMinMaxOffer("advance")}
              </div>
              {!!get(this.state, "minMaxValues.new_release_advance.max") && (
                <div className={styles.otherOfferContainer}>
                  <p>Upfront advance</p>
                  {this.renderMinMaxOffer("catalog_advance")}
                </div>
              )}
              {!!get(this.state, "minMaxValues.new_release_advance.max") && (
                <div className={styles.otherOfferContainer}>
                  <p>New release advance</p>
                  {this.renderMinMaxOffer("new_release_advance")}
                </div>
              )}
              {!!get(this.state, "minMaxValues.option_advance.max") && (
                <div className={styles.otherOfferContainer}>
                  <p>Option advance</p>
                  {this.renderMinMaxOffer("option_advance")}
                </div>
              )}
            </div>
            <div className={styles.offerInfoContainer}>
              {get(this.state, "showMoreData") && this.renderOfferInfo()}
              {!!get(this.state, `minMaxValues.recoup_year_high`) && (
                <div className={styles.royalty}>
                  <p>
                    <span>Time to Recoup</span>
                    <Tooltip
                      place="top"
                      light
                      id="Time to Recoup"
                      delay={100}
                      content={""}
                    />
                  </p>
                  <p>
                    {get(this.state, "minMaxValues.recoup_year_low")} -{" "}
                    {get(this.state, `minMaxValues.recoup_year_high`)} Years
                  </p>
                </div>
              )}
              {!!get(this.state, "currentData.partner") &&
                get(this.state, "currentData.partner") !== DEFAULT_PARTNER && (
                  <div className={styles.royalty}>
                    <p>
                      <span>Distribution Fee </span>
                      <Tooltip
                        place="top"
                        light
                        id="Distribution Fee"
                        delay={100}
                        content={""}
                      />
                    </p>
                    <p>
                      {get(
                        this.state,
                        `partnersOptions.${get(
                          this.state,
                          "currentData.partner",
                        )}.fees`,
                      )}
                    </p>
                  </div>
                )}
              <div
                className={styles.collapseBtn}
                onClick={this.toggleShowMoreData}
              >
                {get(this.state, "showMoreData") ? (
                  <>
                    <p>Hide Details</p> <IconDownArrow className={styles.up} />
                  </>
                ) : (
                  <>
                    <p>Show More Details</p> <IconDownArrow />
                  </>
                )}
              </div>
            </div>

            <div className={styles.submitContainer}>
              <button
                onClick={this.handleSaveOffer}
                disabled={
                  get(this.state, `minMaxValues.advance.min`) !==
                  get(this.state, `minMaxValues.advance.max`)
                }
              >
                Select Deal
              </button>
            </div>
          </div>
          <div className={styles.desktopFinePrint}>
            <button
              className={styles.linkBtn}
              onClick={this.toggleFinePrintText}
            >
              Read the Fine Print
            </button>
          </div>
        </div>
        <div className={styles.container}>
          <div className={styles.titleContainer}>
            <h2>Deal Details</h2>
          </div>
          <div className={styles.selectorContainer}>
            <div className={styles.fieldContainer}>
              <label>
                Included Works
                <span
                  data-testid="WorksTooltip"
                  onClick={() => this.renderVideoContainer(OFFER_KEYS[0])}
                >
                  <ToolTip />
                </span>
              </label>
              <Select
                classNamePrefix="offers"
                styles={distributorSelectStyle}
                onChange={(op) => {
                  this.handleChangeOffer("works", get(op, "value"));
                }}
                options={this.getWorksOptions()}
                placeholder={`Select One`}
                value={
                  get(this.state, "currentData.works") && {
                    label: get(this.state, "currentData.works"),
                    value: get(this.state, "currentData.works"),
                  }
                }
                components={{ IndicatorSeparator: null }}
                aria-label="works-select-input"
              />
            </div>
            <div className={styles.fieldContainer}>
              <label>
                Percent of Income Paid Through Before Recoupment
                <span
                  data-testid="BeforeRecoupTooltip"
                  onClick={() => this.renderVideoContainer(OFFER_KEYS[1])}
                >
                  <ToolTip />
                </span>
              </label>
              <ToggleButtonGroup
                name="flow_through"
                className={styles.toggleButton}
                exclusive
                value={get(this.state, "currentData.flow_through")}
                onChange={(e, val) => {
                  this.handleChangeOffer("flow_through", val);
                }}
              >
                {flowThroughOptions.map((item) => (
                  <ToggleButton
                    key={`flow_through-${item}`}
                    value={item}
                    disableRipple
                  >
                    {item}%
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            </div>
            <div className={styles.fieldContainer}>
              <label>
                Recoupment Rate
                <span
                  data-testid="RecoupmentTooltip"
                  onClick={() => this.renderVideoContainer(OFFER_KEYS[2])}
                >
                  <ToolTip />
                </span>
              </label>
              <ToggleButtonGroup
                name="royalty"
                exclusive
                className={styles.toggleButton}
                value={get(this.state, "currentData.royalty")}
                onChange={(e, val) => {
                  this.handleChangeOffer("royalty", val, false);
                }}
              >
                {(!get(royaltyOptions, "length")
                  ? get(
                      this.state,
                      "filteredOptions.royalty",
                      get(this.state, "groupedValues.royalty", []),
                    )
                  : royaltyOptions
                )
                  .sort((a, b) => parseFloat(a) - parseFloat(b))
                  .map((item) => (
                    <ToggleButton
                      key={`royalty-${item}`}
                      value={item}
                      disableRipple
                    >
                      {item}
                    </ToggleButton>
                  ))}
              </ToggleButtonGroup>
            </div>
            <div className={styles.fieldContainer}>
              <label>
                Post-Recoupment Term Length
                <span
                  data-testid="TermLengthTooltip"
                  onClick={() => this.renderVideoContainer(OFFER_KEYS[3])}
                >
                  <ToolTip />
                </span>
              </label>
              <ToggleButtonGroup
                name="tail-term"
                exclusive
                className={styles.toggleButton}
                value={get(this.state, "currentData.tail_term", 0)}
                onChange={(e, val) => {
                  this.handleChangeOffer("tail_term", val, false);
                }}
              >
                {(!get(tailTermOptions, "length")
                  ? get(
                      this.state,
                      "filteredOptions.tail_term",
                      get(this.state, "groupedValues.tail_term", []),
                    )
                  : tailTermOptions
                ).map((item) => (
                  <ToggleButton
                    key={`tail-term-${item}`}
                    value={item}
                    disableRipple
                  >
                    {item} Years
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            </div>
            {!!parseFloat(get(this.state, "currentData.tail_term")) && (
              <div className={styles.fieldContainer}>
                <label>
                  Income Paid Through After Recoupment
                  <span
                    data-testid="AfterRecoupTooltip"
                    onClick={() => this.renderVideoContainer(OFFER_KEYS[4])}
                  >
                    <ToolTip />
                  </span>
                </label>
                <ToggleButtonGroup
                  name="royalty"
                  exclusive
                  className={styles.toggleButton}
                  value={get(this.state, "currentData.tail_rev_share", "0%")}
                  onChange={(e, val) => {
                    this.handleChangeOffer("tail_rev_share", val, false);
                  }}
                >
                  {(!get(tailShareOptions, "length")
                    ? get(
                        this.state,
                        "filteredOptions.tail_rev_share",
                        get(this.state, "groupedValues.tail_rev_share", []),
                      )
                    : tailShareOptions
                  )
                    .sort((a, b) => parseFloat(b) - parseFloat(a))
                    .map(
                      (item) =>
                        !!parseFloat(item) && (
                          <ToggleButton
                            key={`royalty-${item}`}
                            value={item}
                            disableRipple
                          >
                            {item}
                          </ToggleButton>
                        ),
                    )}
                </ToggleButtonGroup>
              </div>
            )}
            {!!get(this.state, "availablePartners.length") && (
              <div className={styles.fieldContainer}>
                <label>
                  Distributor
                  <span
                    data-testid="DistributorTooltip"
                    onClick={() => this.renderVideoContainer(OFFER_KEYS[5])}
                  >
                    <ToolTip />
                  </span>
                </label>
                <Select
                  classNamePrefix="offers"
                  styles={distributorSelectStyle}
                  onChange={(op) => {
                    this.handleChangeOffer("partner", get(op, "value"));
                  }}
                  options={this.getPartnerOptions()}
                  placeholder={`Select a Distributor`}
                  value={this.getSelectedPartner()}
                  components={{ IndicatorSeparator: null }}
                  formatOptionLabel={this.formatOptionLabel}
                  aria-label="partner-select-input"
                />
              </div>
            )}
          </div>
        </div>
        <div className={styles.mobileFinePrint}>
          <button className={styles.linkBtn} onClick={this.toggleFinePrintText}>
            Read the Fine Print
          </button>
        </div>
      </div>
    );
  };

  renderOfferSteps = () => {
    if (get(this.state, "isOfferScreen")) {
      return this.renderCustomizeOffers();
    } else {
      return (
        <ConfirmedOffersV2
          offerMinMax={this.state.offerMinMax}
          confirmedOfferSteps={this.state.confirmedOfferSteps}
          setLoading={(loading) => {
            this.setState({ loading });
          }}
          setParentState={this.setState.bind(this)}
          {...this.props}
        />
      );
    }
  };

  render() {
    return (
      <div className={styles.layoutContainer}>
        <HorizontalMenu offerStage={this.state.offerStage} {...this.props} />
        <div className={styles.funnelContainer}>
          <LoginHeader
            headerTitle={"CONFIRMED OFFERS"}
            isPartnerFlow={
              get(this.state, "isOfferScreen") ||
              get(this.state, "confirmedOfferSteps.preference") ===
                MAIN_QUESTION_OPTIONS[0].OPTION
            }
            renderCustomContent={
              <div className={styles.headerOfferRange}>
                <p>
                  <span>Confirmed Offers Range</span>{" "}
                  <Tooltip
                    place="bottom"
                    light
                    id="offerRange"
                    content={
                      "This is the range of funding you can choose from when selecting an advance. Not all exact dollar figures within the range will be available."
                    }
                    delay={200}
                  />
                </p>
                <div className={styles.rangeNumbers}>
                  ${getShortNumber(get(this.state, "offerMinMax.min"))} - $
                  {getShortNumber(get(this.state, "offerMinMax.max"))}
                </div>
              </div>
            }
          />

          <div className={styles.pageContainer}>
            <div className={`${styles.mainContainer}`}>
              <div className={`${styles.scrollContainer}`}>
                {this.renderOfferSteps()}
              </div>
            </div>
          </div>
          {(this.state.loading || this.state.loadingRange) && (
            <Loader light backgroundNone />
          )}
          {this.state.shortLoading && (
            <ShortCallLoader
              loaderTitle="Hold tight"
              text={this.loaderText()}
              defaultLoader
            />
          )}
          <FinePrintModal
            isOpen={this.state.isFinePrint}
            onRequestClose={this.toggleFinePrintText}
          />
          {this.renderModal()}
        </div>
      </div>
    );
  }
}

ConfirmedOffers.contextType = ThemeContext;

export default ConfirmedOffers;

ConfirmedOffers.contextTypes = {
  slugName: PropTypes.string,
};
