import React, { useEffect, useState } from "react";
import { get, invoke } from "lodash";
import styles from "./ConfirmedOffersV2.module.scss";
import Select from "react-select";
import { distributorSelectStyle } from "../../../component/UploadFiles/selectStyle";
import { ReactComponent as DropDownArrow } from "../../../assets/logos/dropDownArrow.svg";
import Tooltip from "../../../component/Tooltip/Tooltip";
import { getShortNumber, numberWithCommas } from "../YourAdvance/helper";

const RecommendedDealCard = (props) => {
  const [distributor, setDistributor] = useState();
  const [distributorList, setDistributorList] = useState([]);
  const [offerList, setOfferList] = useState([]);
  const [offerDetails, setOfferDetails] = useState(null);
  const [showMore, setShowMore] = useState(false);
  const [minMaxRange, setMinMaxRange] = useState({
    totalAdvanceMin: 0,
    totalAdvanceMax: 0,
    catalogAdvanceMin: 0,
    catalogAdvanceMax: 0,
    newReleaseAdvanceMin: 0,
    newReleaseAdvanceMax: 0,
    optionAdvanceMin: 0,
    optionAdvanceMax: 0,
    recoupYearMin: 0,
    recoupYearMax: 0,
  });

  const onDistributorChange = (option) => {
    const offerWithPartner = offerList.find(
      (offer) => offer.partner === option.value,
    );
    const distributorOption = distributorList.find(
      (val) => val.value === option.value,
    );
    setDistributor(distributorOption);
    setOfferDetails(offerWithPartner);
  };

  const saveRecommenedCardDeal = (isCustomizeOffer) => {
    let payload = offerDetails;
    if (isCustomizeOffer) {
      payload = offerList[0];
    }
    invoke(props, "saveRecommenedDeal", payload, isCustomizeOffer);
    return true;
  };

  const findMinMaxAdvances = (offerList) => {
    const initialValues = {
      totalAdvanceMin: Infinity,
      totalAdvanceMax: -Infinity,
      catalogAdvanceMin: Infinity,
      catalogAdvanceMax: -Infinity,
      newReleaseAdvanceMin: Infinity,
      newReleaseAdvanceMax: -Infinity,
      optionAdvanceMin: Infinity,
      optionAdvanceMax: -Infinity,
      recoupYearMin: Infinity,
      recoupYearMax: -Infinity,
    };
    return offerList.reduce((acc, offer) => {
      acc.totalAdvanceMin = Math.min(acc.totalAdvanceMin, offer.total_advance);
      acc.totalAdvanceMax = Math.max(acc.totalAdvanceMax, offer.total_advance);

      acc.catalogAdvanceMin = Math.min(
        acc.catalogAdvanceMin,
        offer.catalog_advance || 0,
      );
      acc.catalogAdvanceMax = Math.max(
        acc.catalogAdvanceMax,
        offer.catalog_advance || 0,
      );

      acc.newReleaseAdvanceMin = Math.min(
        acc.newReleaseAdvanceMin,
        offer.new_release_advance || 0,
      );
      acc.newReleaseAdvanceMax = Math.max(
        acc.newReleaseAdvanceMax,
        offer.new_release_advance || 0,
      );

      acc.optionAdvanceMin = Math.min(
        acc.optionAdvanceMin,
        offer.option_advance || 0,
      );
      acc.optionAdvanceMax = Math.max(
        acc.optionAdvanceMax,
        offer.option_advance || 0,
      );

      acc.recoupYearMin = Math.min(
        acc.recoupYearMin,
        offer.recoup_year_lower || 0,
      );
      acc.recoupYearMax = Math.max(
        acc.recoupYearMax,
        offer.recoup_year_higher || 0,
      );

      return acc;
    }, initialValues);
  };

  useEffect(() => {
    if (props?.offers) {
      setOfferList(props.offers);
      const distributorList = [];
      props.offers.forEach((ele) => {
        distributorList.push({
          label: ele.partner,
          value: ele.partner,
          logo: ele.logo,
        });
      });
      setDistributorList(distributorList);
      const minMaxValues = findMinMaxAdvances(props.offers);
      setMinMaxRange(minMaxValues);
    }
  }, [props]);

  const renderTotalAdvance = () => {
    if (offerDetails) {
      return `$${numberWithCommas(offerDetails?.total_advance)}`;
    } else {
      return `$${getShortNumber(minMaxRange?.totalAdvanceMin)} - $${getShortNumber(minMaxRange?.totalAdvanceMax)}`;
    }
  };

  const renderUpfrontAdvance = () => {
    if (offerDetails) {
      return `$${numberWithCommas(offerDetails?.catalog_advance)}`;
    } else {
      return `$${getShortNumber(minMaxRange?.catalogAdvanceMin)} - $${getShortNumber(minMaxRange?.catalogAdvanceMax)}`;
    }
  };

  const renderNewReleaseAdvance = () => {
    if (offerDetails) {
      return `$${numberWithCommas(offerDetails?.new_release_advance)}`;
    } else {
      return `$${getShortNumber(minMaxRange?.newReleaseAdvanceMin)} - $${getShortNumber(minMaxRange?.newReleaseAdvanceMax)}`;
    }
  };

  const renderOptionAdvance = () => {
    if (offerDetails) {
      return `$${numberWithCommas(offerDetails?.option_advance)}`;
    } else {
      return `$${getShortNumber(minMaxRange?.optionAdvanceMin)} - $${getShortNumber(minMaxRange?.optionAdvanceMax)}`;
    }
  };

  const renderTimeToRecoup = () => {
    if (
      minMaxRange.recoupYearMin === -Infinity ||
      minMaxRange.recoupYearMax === 0
    ) {
      return `0 Years`;
    } else if (offerDetails) {
      return `${offerDetails.recoup_year_lower} - ${offerDetails.recoup_year_higher} Years`;
    } else {
      return `${minMaxRange.recoupYearMin} - ${minMaxRange.recoupYearMax} Years`;
    }
  };

  return (
    <div className={styles.dealCard}>
      <div className={styles.dealHeader}>
        <div className={styles.label}>Total Advance</div>
        <div className={styles.totalAdvance}>{renderTotalAdvance()}</div>
      </div>
      <div className={styles.dealDetails}>
        <ul>
          <li>
            <span className={styles.label}>
              Works
              <Tooltip
                place="top"
                light
                id="newTracks"
                content={""}
                delay={200}
              />
            </span>
            <span className={styles.value}>
              {offerDetails ? offerDetails.works : offerList[0]?.works}
            </span>
          </li>
          <li>
            <span className={styles.label}>
              Est. Time to Recoup
              <Tooltip
                place="top"
                light
                id="timeToRecoup"
                content={""}
                delay={200}
              />
            </span>
            <span className={styles.value}>{renderTimeToRecoup()}</span>
          </li>
          <div
            className={`${styles.moreDetailContainer} ${showMore ? styles.active : ""} `}
          >
            <li>
              <span className={styles.label}>
                Recoupment Rate
                <Tooltip
                  place="top"
                  light
                  id="recoupment"
                  content={
                    "This is the percentage of your distribution revenue that we collect that goes toward recouping your advance."
                  }
                  delay={200}
                />
              </span>
              <span className={styles.value}>{offerList[0]?.royalty}</span>
            </li>
            <li>
              <span className={styles.label}>
                Post-Recoupment
                <Tooltip
                  place="top"
                  light
                  id="postRecoupment"
                  content={""}
                  delay={200}
                />
              </span>
              <span className={styles.value}>
                {offerList[0]?.tail_rev_share}
                {offerList[0]?.tail_term
                  ? ` for ${offerList[0]?.tail_term} Years`
                  : ""}
              </span>
            </li>
            <li>
              <span className={styles.label}>
                Distribution Fee
                <Tooltip
                  place="top"
                  light
                  id="distributionFee"
                  content={
                    "This is the annual cost charged by the distributor for their services."
                  }
                  delay={200}
                />
              </span>
              <span className={styles.value}>
                {" "}
                {offerDetails?.fees || "None"}
              </span>
            </li>
            {minMaxRange.catalogAdvanceMin !== -Infinity &&
              minMaxRange.catalogAdvanceMax !== 0 && (
                <li>
                  <span className={styles.label}>Upfront Advance</span>
                  <span className={`${styles.value} ${styles.amount}`}>
                    {renderUpfrontAdvance()}
                  </span>
                </li>
              )}
            {minMaxRange.newReleaseAdvanceMin !== -Infinity &&
              minMaxRange.newReleaseAdvanceMax !== 0 && (
                <li>
                  <span className={styles.label}>New Release Advance</span>
                  <span className={`${styles.value} ${styles.amount}`}>
                    {renderNewReleaseAdvance()}
                  </span>
                </li>
              )}
            {minMaxRange.optionAdvanceMin !== -Infinity &&
              minMaxRange.optionAdvanceMax !== 0 && (
                <li>
                  <span className={styles.label}>Option Advance</span>
                  <span className={`${styles.value} ${styles.amount}`}>
                    {renderOptionAdvance()}
                  </span>
                </li>
              )}
          </div>
        </ul>
        <div
          className={`${styles.collapseArrow} ${showMore && styles.arrowDown} `}
          onClick={() => setShowMore(!showMore)}
        >
          <span className={styles.showMore}>
            {" "}
            {showMore ? "Hide Details" : "Show More Details"}
          </span>
          <DropDownArrow />
        </div>
      </div>
      <div className={styles.dealPartner}>
        <Select
          classNamePrefix="distributor"
          styles={distributorSelectStyle}
          onChange={onDistributorChange}
          options={distributorList}
          placeholder={`Select a Distributor`}
          value={distributor}
          components={{ IndicatorSeparator: null }}
          formatOptionLabel={(val) => (
            <div className={styles.option}>
              {get(val, "logo") && (
                <img src={get(val, "logo")} alt={`${get(val, "label")}-logo`} />
              )}{" "}
              <span>{get(val, "label")}</span>
            </div>
          )}
          aria-label="partner-select-input"
        />
        {!!get(offerDetails, "featuresHTML") && (
          <div className={styles.featureCtn}>
            <button
              className={styles.featureBtn}
              data-testid="view-feature-btn"
              onClick={() => {
                invoke(
                  props,
                  "toggleFeatureModal",
                  get(offerDetails, "partner"),
                );
              }}
            >
              View Distributor Features
            </button>
          </div>
        )}
      </div>
      <div className={styles.dealButton}>
        <button
          className={styles.primary}
          data-testid="select-offer-btn"
          disabled={!distributor}
          onClick={() => saveRecommenedCardDeal(false)}
        >
          Select Offer
        </button>
        <button
          className={styles.secondary}
          data-testid="customize-offer-btn"
          onClick={() => saveRecommenedCardDeal(true)}
        >
          Customize Offer
        </button>
      </div>
    </div>
  );
};

export default RecommendedDealCard;
